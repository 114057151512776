import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { PARAM_SEARCH_QUERY } from '../../../enums/QueryParams'
import useCounter from '../../../hooks/useCounter'
import useCurrentModuleContext from '../../../hooks/useCurrentModuleContext'
import useDebouncedCallback from '../../../hooks/useDebouncedCallback'
import useMapLayer from '../../../hooks/useMapLayer'
import useOLEventListener from '../../../hooks/useOLEventListener'
import useQueryParams from '../../../hooks/useQueryParams'
import { createLogger } from '../../../lib/logger'
import { createSearchResultsStyle, MARKER_Z_INDEX } from '../../../lib/mapStyles'
import useMapContext from '../../map/useMapContext'
import ErrorModal from '../../modals/ErrorModal'
import { SearchContext, SearchContext_, SearchResults_ } from './useSearchContext'
import Feature from 'ol/Feature'
import MapBrowserEvent from 'ol/MapBrowserEvent'

export const SEARCH_MODULE = 'search'

const logger = createLogger({ name: SEARCH_MODULE })

/**
 * Fournisseur du contexte de recherche.
 * @param props
 */
function SearchContextProvider (props: PropsWithChildren) {
  const { children } = props

  const { setCurrentModule } = useCurrentModuleContext()
  const { map } = useMapContext()
  const [queryParams] = useQueryParams()
  const [error, setError] = useState<Error>(null)
  const [isSearchHelpOpen, setIsSearchHelpOpen] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>(null)
  const [selectedFeature, setSelectedFeature] = useState<Feature>(null)

  const setSearchQueryDebounced = useDebouncedCallback(setSearchQuery, 100)

  // Définit la couche de recherche.
  const [searchLayer] = useState(() => new VectorLayer({
    source: new VectorSource(),
    style: createSearchResultsStyle,
    updateWhileAnimating: true,
    updateWhileInteracting: true,
    zIndex: MARKER_Z_INDEX
  }))
  useMapLayer(map, searchLayer)

  const {
    decrement: decrementJobCount,
    increment: incrementJobCount,
    value: jobCount
  } = useCounter(0)

  const {
    increment: incrementResultCount,
    setValue: setResultCount,
    value: resultCount
  } = useCounter(0)

  const {
    increment: incrementSearchCount,
    value: searchCount
  } = useCounter(0)

  /**
   * Ferme la modale d'erreur.
   */
  const closeErrorModal = useCallback(() => {
    setError(null)
  }, [])

  /**
   * Lance une recherche de texte.
   * @param query
   */
  const executeSearch = useCallback((query: string) => {
    logger.debug('Execute search', { query })

    // Efface la feature sélectionnée.
    setSelectedFeature(null)

    // Efface la couche des résultats de recherche.
    searchLayer.getSource().clear()

    // Modifie la requête de recherche.
    setSearchQueryDebounced(query)
    incrementSearchCount()

    // Réinitialise le nombre de résultats à chaque nouvelle recherche.
    setResultCount(0)

    // Affiche le panneau des résultats de recherche.
    setCurrentModule(SEARCH_MODULE)
  }, [
    incrementSearchCount, searchLayer, setCurrentModule, setResultCount,
    setSearchQueryDebounced
  ])

  /**
   * Vérifie si la feature est gérée par le module.
   * @param feature
   */
  const isSearchFeature = useCallback((feature: Feature) => (
    searchLayer.getSource().hasFeature(feature)
  ), [searchLayer])

  /**
   * Vérifie si le clic de carte est géré par le module de recherche.
   */
  const isMapClickEventHandled = useCallback((event: MapBrowserEvent<MouseEvent>) => (
    map.getFeaturesAtPixel(event.pixel).filter(isSearchFeature).length > 0
  ), [map, isSearchFeature])

  /**
   * Gère le lancement d'une recherche.
   */
  const startSearch = useCallback((promise: Promise<SearchResults_>) => {
    incrementJobCount()
    return promise
      .then((r) => {
        incrementResultCount(r.totalElements)
        return r
      })
      .catch((err: Error) => {
        logger.error(err.message)
        setError(err)
      })
      .finally(() => {
        decrementJobCount()
      })
  }, [decrementJobCount, incrementJobCount, incrementResultCount])

  /**
   * Affiche ou cache l'aide de recherche.
   */
  const toggleSearchHelp = useCallback(() => {
    setIsSearchHelpOpen((s) => !s)
  }, [setIsSearchHelpOpen])

  // Affiche le module lorsqu'une recherche est sélectionnée sur la carte.
  useOLEventListener(map, 'singleclick', (event: MapBrowserEvent<MouseEvent>) => {
    if (isMapClickEventHandled(event)) {
      const clickedFeature = map.getFeaturesAtPixel(event.pixel).shift()

      if (clickedFeature instanceof Feature) {
        setSelectedFeature(clickedFeature)
        setCurrentModule(SEARCH_MODULE)
      }
    }
  })

  // Affiche le panneau de recherche lorsque la recherche est terminée.
  useEffect(() => {
    if (searching) {
      setCurrentModule(SEARCH_MODULE)
    }
  }, [searching, setCurrentModule])

  // Affiche ou cache la feature sélectionnée sur la carte.
  useEffect(() => {
    const source = searchLayer.getSource()

    if (selectedFeature && !source.hasFeature(selectedFeature)) {
      source.addFeature(selectedFeature)
    }
    return () => {
      if (selectedFeature && source.hasFeature(selectedFeature)) {
        source.removeFeature(selectedFeature)
      }
    }
  }, [searchLayer, selectedFeature])

  // Définit l'état de recherche en cours en fonction du nombre de recherches
  // en cours.
  useEffect(() => {
    setSearching(jobCount > 0)
  }, [jobCount, setSearching])

  useEffect(() => {
    if (searchQuery) {
      // Cache le panneau d'aide à la recherche lorsque la recherche est modifiée.
      setIsSearchHelpOpen(false)
    }
  }, [searchQuery])

  // Lance la recherche si la requête est présente dans l'URL.
  const searchQueryParam = queryParams[PARAM_SEARCH_QUERY]
  useEffect(() => {
    if (searchQueryParam) {
      executeSearch(searchQueryParam)
    }
  }, [executeSearch, searchQueryParam])

  const providerContext = useMemo<SearchContext_>(() => ({
    isSearchHelpOpen,
    resultCount,
    searching,
    searchCount,
    searchLayer,
    searchQuery,
    selectedFeature,
    setIsSearchHelpOpen,
    setSearching,
    setSearchQuery: executeSearch,
    setSelectedFeature,
    startSearch,
    toggleSearchHelp
  }), [
    executeSearch,
    isSearchHelpOpen,
    resultCount,
    searchCount,
    searchLayer,
    searchQuery,
    searching,
    selectedFeature,
    startSearch,
    toggleSearchHelp
  ])

  return (
    <SearchContext.Provider value={providerContext}>
      {children}

      <ErrorModal
        onClose={closeErrorModal}
        opened={error != null}
      >
        {error?.message}
      </ErrorModal>
    </SearchContext.Provider>
  )
}

export default SearchContextProvider
