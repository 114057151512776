import { Alert, Button, Group, Modal, Select, Stack, Text, TextInput } from '@mantine/core'
import Feature from 'ol/Feature'
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import ExportFormats, { ExportFormat, FORMAT_GPX } from '../../../enums/ExportFormats'
import { handleFieldChange, handleValueChange } from '../../../lib/form'
import { containsGeometries } from '../../../lib/geometry'

export type ExportFeaturesOptions = {
  filename?: string
  format?: ExportFormat
}

export type ExportFeaturesModalProps = {
  defaultOptions: ExportFeaturesOptions
  features: Feature[]
  onClose (): void
  onValidate (options: ExportFeaturesOptions, features: Feature[]): void
  show: boolean
}

/**
 * Modale d'exportation des features.
 */
function ExportFeaturesModal (props: ExportFeaturesModalProps) {
  const {
    defaultOptions,
    features,
    onClose,
    onValidate,
    show
  } = props

  const [fields, setFields] = useState<ExportFeaturesOptions>(defaultOptions)

  const has = useMemo(() => ({
    circles: containsGeometries(['Circle'], features),
    polygons: containsGeometries(['Polygon'], features)
  }), [features])

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault()
    onValidate(fields, features)
  }, [features, fields, onValidate])

  const setField = useCallback((name: keyof ExportFeaturesOptions, value: string) => {
    setFields((s) => ({
      ...s,
      [name]: value
    }))
  }, [setFields])

  useEffect(() => {
    if (show) {
      setFields((s) => ({
        ...s,
        filename: defaultOptions?.filename
      }))
    }
  }, [defaultOptions?.filename, setFields, show])

  return (
    <Modal
      centered
      opened={show}
      onClose={onClose}
      title="Exportation des dessins"
    >
      <form
        method="post"
        onSubmit={handleSubmit}
      >
        <Stack>
          <Select
            allowDeselect={false}
            data-type="string"
            id="formatField"
            label="Format"
            name="format"
            onChange={handleValueChange('format', setField)}
            required
            value={fields.format || ''}
            data={ExportFormats}
          />

          {fields.format === FORMAT_GPX && (has.circles || has.polygons)
            ? <Alert color="yellow">
              Attention, le format GPX ne supporte pas certaines géométries, les polygones ne
              seront pas exportés et les
              cercles seront remplacés par des points.
            </Alert>
            : null}

          <TextInput
            data-type="string"
            label="Nom du fichier"
            name="filename"
            onChange={handleFieldChange(setField)}
            required
            value={fields.filename || ''}
            rightSectionWidth={100}
            rightSection={(
              <Text>
                {`.${fields.format?.toLowerCase()}`}
              </Text>
            )}
          />

          <Group justify="right">
            <Button
              onClick={onClose}
              variant="light"
            >
              Annuler
            </Button>
            <Button
              onClick={handleSubmit}
              type="submit"
            >
              Exporter
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default ExportFeaturesModal
