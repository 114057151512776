import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { inputValue } from '@jalik/react-form'
import {
  ActionIcon,
  Autocomplete,
  Box,
  BoxProps,
  ComboboxItem,
  ComboboxParsedItem,
  ComboboxParsedItemGroup,
  Group
} from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BTN_SEARCH_ID } from '../../../enums/UI'
import useCurrentModuleContext from '../../../hooks/useCurrentModuleContext'
import ConfigurableUIComponent from '../../ConfigurableUIComponent'
import { SEARCH_MODULE } from './SearchContextProvider'
import useSearchContext from './useSearchContext'
import { useDebouncedValue, useNetwork } from '@mantine/hooks'
import { useQuery } from '@tanstack/react-query'
import rest from '../../../lib/rest'
import { getBaseUrl } from '../../../lib/common'
import { stringify } from 'qs'
import { ElasticSearchResponse } from './extensions/GlobalSearch'

/**
 * Nombre de résultats affichés par page.
 */
export const MAX_RESULTS_PER_PAGE = 10

export const AUTOCOMPLETE_MIN_LENGTH = 2

// function renderSuggestion ({ option }: ComboboxLikeRenderOptionInput<ComboboxStringItem>) {
//   return (
//     <Group wrap="nowrap" align="start">
//       <FontAwesomeIcon icon={faSearch} />
//       <span>{option.value}</span>
//     </Group>
//   )
// }

export type MapSearchFormProps = BoxProps & Partial<Pick<HTMLFormElement, 'id'>>

/**
 * Formulaire de recherche.
 */
function MapSearchForm (props: MapSearchFormProps) {
  const { ...others } = props
  const {
    currentModule,
    setCurrentModule,
    setIsPanelOpen
  } = useCurrentModuleContext()

  const {
    isSearchHelpOpen,
    searching,
    searchQuery,
    setIsSearchHelpOpen,
    setSearchQuery
  } = useSearchContext()

  const networkStatus = useNetwork()

  const [focused, setFocused] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(searchQuery)
  const [suggestionsOpened, setSuggestionsOpened] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>()
  const [autocompleteSearch] = useDebouncedValue<string>(search, 100)

  const autocompleteQuery = useQuery({
    enabled: autocompleteSearch != null && autocompleteSearch.length > AUTOCOMPLETE_MIN_LENGTH,
    queryKey: ['searchAutoComplete', autocompleteSearch],
    queryFn: () => rest.get<RestPaginatedResult<ElasticSearchResponse>>(getBaseUrl(`/api/recherche/complete?${stringify({ q: autocompleteSearch })}`)),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  const suggestions: ComboboxParsedItemGroup[] = useMemo(() => ([
    {
      group: 'Suggestions',
      // Filtre les doublons pour éviter une erreur Mantine (option duplicate).
      items: (Array.from(new Set(
        autocompleteQuery.data?.body?.content?.hits?.hits
          // Retourne uniquement le titre.
          ?.map((el) => el._source.resultat_titre)
      )) || [])
        .map((el) => ({
          label: el,
          value: el
        }) as ComboboxItem)
    }
  ]), [autocompleteQuery.data?.body?.content?.hits?.hits])

  /**
   * Gère la sortie du champ de recherche.
   */
  const handleBlurSearch = useCallback(() => {
    setFocused(false)
  }, [])

  /**
   * Défini la valeur du champ de recherche.
   */
  const handleChangeSearch = useCallback((query: string) => {
    setSearch(query)

    if (query != null && query.length > 0) {
      setIsPanelOpen(false)
      setIsSearchHelpOpen(false)
    }
  }, [setIsPanelOpen, setIsSearchHelpOpen])

  /**
   * Gère le focus dans le champ de recherche.
   */
  const handleFocusSearch = useCallback(() => {
    setFocused(true)

    if (search == null || search.length === 0) {
      setIsSearchHelpOpen(true)
      setCurrentModule(SEARCH_MODULE)
    }
  }, [search, setCurrentModule, setIsSearchHelpOpen])

  /**
   * Gère la soumission de formulaire.
   */
  const handleSubmitForm = useCallback((event: React.FormEvent) => {
    event.preventDefault()
    inputRef.current.blur()
    setSearchQuery(search)
  }, [setSearchQuery, search])

  /**
   * Gère la soumission de formulaire.
   */
  const handleSubmitOption = useCallback((option: string) => {
    inputRef.current.blur()
    setSearchQuery(option)
  }, [setSearchQuery])

  /**
   * Gère l'affichage de l'aide à la recherche.
   */
  const handleToggleHelp = useCallback(() => {
    if (isSearchHelpOpen && currentModule === SEARCH_MODULE) {
      setIsSearchHelpOpen(false)
    } else {
      setIsSearchHelpOpen(true)
      setCurrentModule(SEARCH_MODULE)
    }
  }, [currentModule, isSearchHelpOpen, setCurrentModule, setIsSearchHelpOpen])

  // Synchronise la valeur du champ de recherche avec la recherche.
  useEffect(() => {
    if (searchQuery) {
      setSearch(searchQuery)
    }
  }, [searchQuery, setSearch])

  useEffect(() => {
    if (focused && !searching && suggestions.length > 0) {
      setSuggestionsOpened(true)
    } else {
      setSuggestionsOpened(false)
    }
  }, [focused, search, searchQuery, searching, suggestions.length])

  return (
    <ConfigurableUIComponent
      id={BTN_SEARCH_ID}
      showByDefault
    >
      <Box
        component={'form'}
        method="post"
        onSubmit={handleSubmitForm}
        {...others}
      >
        <Group
          wrap="nowrap"
          gap={0}
        >
          <Autocomplete
            ref={inputRef}
            comboboxProps={{ position: 'bottom', offset: 0 }}
            dropdownOpened={suggestionsOpened}
            data={suggestions}
            data-type="string"
            disabled={!networkStatus.online}
            filter={() => {
              // Retourne toutes les suggestions retournées par l'API (annule le filtrage).
              return suggestions.map((group, index) => ({
                ...group,
                items: suggestions[index].items
              } as ComboboxParsedItem))
            }}
            id="search-field"
            limit={10}
            name="q"
            placeholder="Rechercher dans Te Fenua"
            onBlur={handleBlurSearch}
            onChange={handleChangeSearch}
            onFocus={handleFocusSearch}
            onOptionSubmit={handleSubmitOption}
            // renderOption={renderSuggestion}
            rightSectionWidth={62}
            rightSection={(
              <>
                <ActionIcon
                  color={isSearchHelpOpen ? 'active' : 'dimmed'}
                  onClick={handleToggleHelp}
                  title={isSearchHelpOpen ? 'Cacher l\'aide' : 'Afficher l\'aide'}
                  variant="subtle"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    fixedWidth
                  />
                </ActionIcon>
                <ActionIcon
                  disabled={searching || !search || !networkStatus.online}
                  title="Rechercher"
                  type="submit"
                  variant="subtle"
                >
                  {searching
                    ? <FontAwesomeIcon
                      icon={faSpinner}
                      fixedWidth
                      spin
                    />
                    : <FontAwesomeIcon
                      icon={faSearch}
                      fixedWidth
                    />
                  }
                </ActionIcon>
              </>
            )}
            type="text"
            value={inputValue(search)}
            w="100%"
          />
        </Group>
      </Box>
    </ConfigurableUIComponent>
  )
}

export default MapSearchForm
