import DrawInteraction, { createBox } from 'ol/interaction/Draw'
import { useMemo } from 'react'
import { createDrawBoxStyle } from '../lib/mapStyles'
import useMapInteraction from './useMapInteraction'
import OlMap from 'ol/Map'

/**
 * Retourne une interaction permettant de tracer une zone.
 * @param map
 */
function useDrawBoxInteraction (map: OlMap): DrawInteraction {
  const interaction = useMemo(() => (
    new DrawInteraction({
      geometryFunction: createBox(),
      snapTolerance: 10,
      style: createDrawBoxStyle,
      type: 'Circle'
    })
  ), [])

  useMapInteraction(map, interaction)

  return interaction
}

export default useDrawBoxInteraction
