import { createContext, SetStateAction, useContext } from 'react'
import DrawInteraction, { Mode } from 'ol/interaction/Draw'
import VectorSource from 'ol/source/Vector'
import SelectInteraction from 'ol/interaction/Select'
import Feature from 'ol/Feature'
import VectorImageLayer from 'ol/layer/VectorImage'
import { Modify } from 'ol/interaction'

export type DrawMode = 'select' | 'draw' | 'modify' | 'none'

export type DrawShape = Mode | 'Box'

export type DrawContext_ = {
  drawInteraction: DrawInteraction
  drawLayer: VectorImageLayer<VectorSource>
  drawMode: DrawMode
  drawShape: DrawShape
  importFile (): void
  importing: boolean
  isListOpen: boolean
  modifyInteraction: Modify
  selectInteraction: SelectInteraction
  setFeatureToRemove (feature: SetStateAction<Feature>): void
  toggleClearModal (): void
  toggleDrawShape (shape: DrawShape): void
  toggleExportModal (): void
  toggleList (): void
  toggleModifyMode (): void
}

/**
 * Contexte de dessin.
 */
export const DrawContext = createContext<DrawContext_>(null)

/**
 * Retourne le contexte de dessin.
 */
function useDrawContext () {
  return useContext(DrawContext)
}

export default useDrawContext
